import request from '@/utils/request2'

function returnHeader(headers) {
  return {
    'x-5a-temp-token': headers
  }
}

export function getInspectionInfoFromToken(headers) {
  return request({
    url: '/local/inspection/sharing/page/info',
    method: 'post',
    headers: returnHeader(headers),
    data: {}
  })
}

export function appendInspection(query, headers) {
  return request({
    url: '/local/inspection/appendInspection',
    method: 'post',
    headers: returnHeader(headers),
    data: query
  })
}

//  发送验证码
export function sendVerification(query) {
  return request({
    url: '/vn/oms/login/verification/send',
    method: 'post',
    data: query
  })
}

export function claimSubmitV2(query) {
  return request({
    url: '/vn/oms/guest/claim/create/v2',
    method: 'post',
    data: query
  })
}

// Claim Portal 定损
export function claimOldProdSubmitV2(query) {
  return request({
    url: '/vn/oms/guest/claim/submit/v2',
    method: 'post',
    data: query
  })
}

// Get Inspection Information
export function getInspectionInfo(query, headers) {
  return request({
    url: '/local/inspection/getInspection/info',
    method: 'post',
    headers: returnHeader(headers),
    data: query
  })
}

// Send OTP Code
export function sendOTPCode(query, headers) {
  return request({
    url: '/local/verification/send/otp/code',
    method: 'post',
    headers: returnHeader(headers),
    data: query
  })
}

// Verify OTP Code
export function verifyOTPCode(query, headers) {
  return request({
    url: '/local/verification/verify/otp/code',
    method: 'post',
    headers: returnHeader(headers),
    data: query
  })
}
