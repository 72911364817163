<template>
  <div class="fu-lang">
    <span
      v-for="(item, index) in langs"
      :key="index"
      class="lang-item"
      :class="{active: currentLang == item.value}"
      @click="handleToggle(item)"
    >{{ item.label }}</span>
  </div>
</template>

<script>

import store from '../../../../store'

export default {
  name: 'Lang',
  props: {
    langs: { type: Array, default: () => [
      { label: 'EN', value: 'en' },
      { label: 'VN', value: 'vi' }
    ] }
  },
  data() {
    return {
      currentLang: 'en'
    }
  },
  mounted() {
    this.handleToggle({ label: 'VN', value: 'vi' })
  },
  methods: {
    handleToggle(lang) {
      this.currentLang = lang.value
      this.$i18n.locale = lang.value

      store.dispatch('base/changeLang', lang.value)
      this.$emit('changeLang', lang.value)
    }
  }
}
</script>

<style lang="scss">
.fu-lang {
  position: absolute;
  top: 3%;
  right: 4%;
  display: flex;
  border: 1px solid #FFBFBF;
  overflow: hidden;
  z-index: 999;
  padding: 2px;
  background: #FBFBFB;
  border-radius: 5px;

  .lang-item {
    padding: 2px;
    width: 35px;
    font-size: 15px;
    text-align: center;
    color: #D90109 ;
    background: #FBFBFB;
    border-radius: 5px;

    &.active {
    color: #FBFBFB;
    background: #D90109;
    }
  }
}
</style>
